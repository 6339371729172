import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let partA = this.element.dataset.partA
    let partB = this.element.dataset.partB
    let mailtoLink = `mailto:${partA}@${partB}`

    this.element.href = mailtoLink

    if (this.element.dataset.replaceContent === "true") {
      this.element.textContent = `${partA}@${partB}`
    }
  }
}